<template>
  <div class="container">

    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="toSearch">搜索</div>
      </template>
    </van-search>


    <van-empty :description="word" v-if="courseList[0].children.length == 0 && courseList[1].children.length == 0" />
    <div v-if="courseList[0].children.length > 0 || courseList[1].children.length > 0">
      <div v-for="(item, i) in courseList" :key="i" class="home-model">
        <div class="model-title">
          <p>{{item.title}}</p>
        </div>
        <div class="course-list">
          <div v-for="(children, c) in item.children" :key="c" class="course-card" @click="toBVue('/courseDetail', children.id)">
            <span v-if="children.author" class="card-horn">{{children.author_title}}</span>
            <img :src="children.cover" alt="">
            <p>{{children.title}}</p>
            <p class="teacher">{{children.teacher}} {{children.teacherTitle}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
export default {
  name: 'HotCourse',
  data(){
    return {
      value:'',
      keyword: '',
      word: '数据加载中...',
      courseList:[
        {
          title:'系列课程',
          children:[]
        },
        {
          title:'单节课程',
          children:[]
        }
      ]
    }
  },
  created() {
    this.course(1)
    this.course(2)
  },
  mounted() {
  },
  methods:{
    toSearch() {
      this.courseList[0].children = []
      this.courseList[1].children = []
      this.course(1)
      this.course(2)
    },
    course(type) {
      let that = this
      this.$api.COURSE_GET({
        'offset': 0,
        'limit': 10,
        'type': type,
        'keyword': that.keyword
      }).then(res => {
        if (res.length === 0) {
          that.word = '暂无数据'
          return false
        }
        if (type == 1) { //系列
          res.forEach((item) => {
            that.courseList[0].children.push({
              id: item.id,
              title: item.title,
              cover: item.cover ? item.cover : '',
              author: item.author,
              author_title: item.author_title
            })
          })
        } else {
          res.forEach((item) => {
            that.courseList[1].children.push({
              id: item.id,
              title: item.title,
              cover: item.cover ? item.cover : '',
              author: item.author,
              author_title: item.author_title
            })
          })
        }
      })
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  }
}
</script>

<style lang='less' scoped>
  .home-model{
    margin-top:14px;
  }

  .container .home-model:first-child{
    margin-top:28px;
  }

  .course-card{
    margin-bottom:15px;
  }
</style>
